import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import { MsalProvider } from '@azure/msal-react';
// import { msalConfig } from './authConfig';
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import * as serviceWorker from './serviceWorker';

// const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    {/* <MsalProvider instance={msalInstance}> */}
    <App />
    {/* </MsalProvider> */}
  </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
  