const initialState = {

    edit_msg_id:'',
    type_indicator:null,
    gallery_tag_filter:'all',
    gallery_tag_filter_list:[],
    get_selectedTag:[],
    re_click_conv: false,
    normal_msg_text: '',
    reply_msg_text:'',
    editor_text:'',
    edit_checklist:null,
    leftTagCounter:0,
}



function actionReducer(state = initialState, action) {
    switch (action.type) {


        case 'setEditMsgId':
            return {
                ...state,
                edit_msg_id: action.payload
            }
            
        
        case 'set_editor_text':
            return {
                ...state,
                editor_text: action.payload
            }
            
        case 'setEditChecklist':
            return {
                ...state,
                edit_checklist: action.payload
            }
            
        case 'set_normal_msg_text':
            return {
                ...state,
                normal_msg_text: action.payload
            }
            
        case 'set_reply_msg_text':
            return {
                ...state,
                reply_msg_text: action.payload
            }
            
        case 'setTypeIndicator':
            return {
                ...state,
                type_indicator: action.payload
            }
            
        
        case 'set_gallery_tag_filter':
            return {
                ...state,
                gallery_tag_filter: action.payload
            }
            
        case 'set_gallery_tag_filter_list':
            return {
                ...state,
                gallery_tag_filter_list: action.payload
            }
        
        case 'set_selectedTag':
            return {
                ...state,
                get_selectedTag: action.payload
            }
        
        case 'set_leftTagCounter':
            return {
                ...state,
                leftTagCounter: action.payload
            }
        
        case 'set_re_click_conv':
            return {
                ...state,
                re_click_conv: action.payload
            }
        
        case 'RESET_APP' : 
            return initialState
        
        default: {
            return {
                ...state
            }
        }
    }
}

export default actionReducer;