import {combineReducers,createStore} from 'redux';
import rootReducer from './reducers/rootReducer';
import popupReducer from './reducers/popupReducer';
import rightSectionReducer from './reducers/rightsectionReducer';
import actionReducer from './reducers/actionReducer';


// combining two reducers into a single reducer
const reducer = combineReducers(
  { 
      rootReducer:rootReducer,
      popupReducer:popupReducer,
      rightSectionReducer:rightSectionReducer,
      actionReducer:actionReducer,
  }
)

const store = createStore(reducer);

export default store;