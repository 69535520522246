const initialState = {
    is_all_msg_loaded:false,
    is_all_reply_loaded:false, //change
    srollType: 'initial',
    redirect_conv: null,
    msg_loading: false,
    private_user:[],
    notifications:[],
    conv_calling_data:null

}



function rightSectionReducer (state=initialState,action){
    // console.log(14,action)
    switch(action.type){
        case 'set_is_all_msg_loaded' : 
        return {
            ...state,
            is_all_msg_loaded : action.payload
        }
        case 'set_is_all_reply_loaded' : //change
        return {
            ...state,
            is_all_reply_loaded : action.payload
        }
        
        case 'setRedirectConv' : 
        return {
            ...state,
            redirect_conv : action.payload
        }
        
        case 'set_srollType' : 
        return {
            ...state,
            srollType : action.payload
        }
        
        case 'set_msg_loading' : 
        return {
            ...state,
            msg_loading : action.payload
        }
        
        case 'setPrivateUser' : 
        return {
            ...state,
            private_user : action.payload
        }
        
        case 'setNotifications' : 
        return {
            ...state,
            notifications : action.payload
        }
        case 'addNewNotificaiton' : 
        return {
            ...state,
            notifications : [action.payload,...state.notifications]
        }
        case 'set_conv_calling_data' : 
        return {
            ...state,
            conv_calling_data : action.payload
        }
        
        case 'RESET_APP' : 
        return initialState
        
        default: {
            return {
              ...state
            }
          }
    }
}

export default rightSectionReducer;